<template>
  <div class="flex flex-wrap py-5">
    <div class="w-11/12 sm:w-9/12 lg:w-1/2 xl:w-1/3 m-auto">
      <div class="rounded overflow-hidden shadow-lg bg-white bg-opacity-90 border p-4">
        <h2 class="font-bold text-green-950 mb-4 text-lg">{{$t('This is already brought:')}}</h2>
        <h3 class="font-bold text-green-950 mb-2">{{ $t('Starters') }}</h3>
        <ul class="list-disc list-inside mb-4">
          <li v-for="starter in starters" :key="starter">1x {{ starter }}</li>
        </ul>
        <h3 class="font-bold text-green-950 mb-2">{{ $t('Desserts') }}</h3>
        <ul class="list-disc list-inside mb-4">
          <li v-for="dessert in desserts" :key="dessert">1x {{ dessert }}</li>
        </ul>
        <h3 class="font-bold text-green-950 mb-2">{{ $t('Others') }}</h3>
        <ul class="list-disc list-inside mb-4">
          <li v-for="other in others" :key="other">1x {{ other }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FoodList',
  computed: {
    starters() {
      return JSON.parse(document.getElementById('app').dataset.starters);
    },
    desserts() {
      return JSON.parse(document.getElementById('app').dataset.desserts);
    },
    others() {
      return JSON.parse(document.getElementById('app').dataset.others);
    }
  }
}
</script>

<style scoped>

</style>
