import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import titleMixin from './mixins/titleMixin'
import App from './App.vue';
import './css/style.css';


import de from './locales/de.json';
import en from './locales/en.json';

const i18n = createI18n({
    locale: getBrowserLang(),
    fallbackLocale: 'en',
    messages: { de, en },
});

createApp(App).mixin(titleMixin).use(i18n).mount('#app');


function getBrowserLang() {
    let langParts = navigator.language.split('-');
    return langParts[0];
}
