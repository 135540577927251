<template>
  
  <div class="flex flex-wrap py-5">
    <div class="w-11/12 sm:w-9/12 lg:w-1/2 xl:w-1/3 m-auto">
      <div class="rounded overflow-hidden shadow-lg bg-white bg-opacity-90 border">
        <div class="bg-image pt-3 pb-5 px-2">
          <LocaleSelect/>
          <h1 class="text-center font-bold text-green-950 text-2xl mb-4">{{ $t('title')}}</h1>
        </div>
        
        <div class="p-4">
          <p class="mb-3"><span class="font-bold text-green-950">{{ $t('when') }}?</span><br>{{$t('Saturday')}}, 20. {{$t('July')}} 2024, {{$t('from')}} 17 {{$t('p.m.')}} - 23 {{$t('p.m.')}}<br>{{$t('Alternative date in case of very bad weather')}}: 27.07.2024</p>
          <p class="mb-3"><span class="font-bold text-green-950">{{ $t('where') }}?</span><br>Rosa-Bauer-Weg, {{$t('corner at')}} Nr. 8</p>
          <p class="mb-3"><span class="font-bold text-green-950">{{ $t('who') }}?</span><br>{{$t('Residents of the Green Mid I / II + friends')}} ({{$t('Private event')}})</p>
          
          <h2 class="font-bold text-green-950 mb-3">{{$t('What is organized?')}}</h2>
          <ul class="list-disc list-inside mb-3">
            <li>{{ $t('Seating and tables') }}</li>
            <li>{{ $t('Draft beer, mineral water, sodas') }}</li>
            <li>{{ $t('BBQ facilities') }}</li>
            <li>{{ $t('Bouncy Castle') }} ({{ $t('Only at fair weather at initial date') }})</li>
          </ul>
          <h2 class="font-bold text-green-950 mb-3">{{ $t('What should you bring?')}}</h2>
          <ul class="list-disc list-inside mb-5">
            <li>{{ $t('Food to be grilled') }}</li>
            <li>{{ $t('Own cutlery, plates and mugs/glasses/beer mug') }}</li>
            <li>{{ $t('Please help us cover our expenses with volntary contributions. Donation boxes are available. Many thanks for your support.') }}</li>
          </ul>
  
          <h2 class="font-bold text-green-950 text-xl">{{$t('Register')}}</h2>
          <p>{{ $t('In order to facilitate the organization and to be able to better estimate the required quantities of drinks, etc., please register below') }}:</p>
          <p class="mb-5"><span class="font-bold">{{ $t('Registration until') }}:</span> {{ $t('Friday') }}, 12. {{ $t('July') }} 2024</p>
          <RegistrationForm/>
        
        </div>
      </div>
    </div>
  </div>
  
 <FoodList/>
  
</template>

<script>
import RegistrationForm from "@/components/RegistrationForm.vue";
import LocaleSelect from "@/components/LocaleSelect.vue";
import FoodList from "@/components/FoodList.vue";

// https://levelup.gitconnected.com/how-to-build-a-multi-language-vue-3-vite-app-b91c34c46097
// https://blog.devgenius.io/how-to-create-a-multiple-page-application-with-laravel-9-and-vue-3-9d3026797fc2

export default {
  name: 'App',
  components: {
    FoodList,
    LocaleSelect,
    RegistrationForm
  },
  title: function() {
    return this.$t('title')
  }
}
</script>
