<template>
  <label :for="id" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{ label }}</label>
  <select :id="id" :name="name"
          @input="$emit('update:modelValue', $event.target.value)"
          v-bind="$attrs"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-800 focus:border-green-800 block w-full p-2.5">
    <option value="">-- bitte auswählen --</option>
    <option v-for="option in useOptions" :key="option.value" :value="option.value">
      {{ option.text }}
    </option>
  </select>
</template>

<script>

export default {
  name: "FormSelect",
  props: {
    id: String,
    name: {
      default: (props) => props.id,
      type: String
    },
    label: String,
    options: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    useOptions() {

      let newOptions = [];//this.options;
      if(this.options.length > 0 && typeof this.options[0] !== 'object') {
        for(let i = 0;i < this.options.length; i++ ) {
          newOptions.push({
            value: this.options[i],
            text:this.options[i]
          });
        }
      } else {
        newOptions = this.options;
      }

      return newOptions;
    }
  }
}
</script>
