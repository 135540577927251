<template>
  <form v-if='showForm && !loading' @submit.prevent="submit">
    
    <div class="mb-6">
      <FormInput
          type="text"
          v-model="form.name"
          id="name"
          :label="$t('form.name')"
          :placeholder="$t('Firstname Lastname')"
          required
      />
    </div>

    <div class="mb-6">

      <FormSelect
          v-model="form.house"
          id="house"
          :label="$t('form.house')"
          :options="['Ludwig-Specht-Straße 1', 'Ludwig-Specht-Straße 3', 'Ludwig-Specht-Straße 5', 'Pfarrer-Erhard-Weg 2', 'Pfarrer-Erhard-Weg 4', 'Pfarrer-Erhard-Weg 6', 'Rosa-Bauer-Weg 1', 'Rosa-Bauer-Weg 2', 'Rosa-Bauer-Weg 3', 'Rosa-Bauer-Weg 4', 'Rosa-Bauer-Weg 5', 'Rosa-Bauer-Weg 6', 'Rosa-Bauer-Weg 8', 'Rosa-Bauer-Weg 9', 'Rosa-Bauer-Weg 10', 'Rosa-Bauer-Weg 11', 'Rosa-Bauer-Weg 12', 'Rosa-Bauer-Weg 13', 'Rosa-Bauer-Weg 14', 'Rosa-Bauer-Weg 16']"
          required
      />
    </div>

    <div class="flex flex-row gap-4">
      <div class="basis-1/2">
        <div class="mb-6">

          <FormInput
              type="number"
              min="0"
              v-model="form.adults"
              id="adults"
              :label="$t('Number of adults')"
              pattern="\d*"
              required
          />
        </div>
      </div>
      <div class="basis-1/2">
        <div class="mb-6">
          <FormInput
              type="number"
              min="0"
              v-model="form.children"
              id="children"
              :label="$t('Number of children')"
              pattern="\d*"
          />
        </div>
      </div>
    </div>


      <h3 class="font-bold text-green-950 text-lg mb-3">{{ $t('Where can I / can we support?')}}</h3>

      <FormCheckbox
          v-model="form.help_start"
          id="help-start"
          :label="$t('Help with setup (approx. 2 hours before start)')"
      />

      <FormCheckbox
          v-model="form.help_end"
          id="help-end"
          :label="$t('Help with dismantling (at night or the next morning)')"
      />

    <FormInput
        v-if="form.help_start || form.help_end"
        type="text"
        v-model="form.contact"
        id="contact"
        :label="$t('E-mail address')"
        placeholder="optional"
        class="mb-5"
    />

    <h4 class="font-bold text-green-950 mb-3 mt-5">{{$t('Voluntary buffet contribution')}}</h4>
    <p class="mb-4">{{$t('Feel free to bring food to share with neighbors.')}}<br>{{$t('Please take a quick look at the list at the bottom of this page beforehand.')}}</p>
    <div class="mb-6">

      <FormSelect
          v-model="form.food_type"
          id="house"
          :label="$t('Type')"
          :options="[{value:1,text:$t('Starters')}, {value:2,text:$t('Dessert')}, {value:3,text:$t('Others')}]"
      />
    </div>
    <div class="mb-6">
      <FormInput
          v-model="form.food"
          id="children"
          :label="$t('What I bring')"
      />
    </div>

<hr class="mb-5">

    
    <div class="mb-8">
      <label for="notes" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{$t('Other notes')}}</label>
      <textarea v-model="form.notes" id="notes" rows="4" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-green-800 focus:border-green-800 block w-full p-2.5" maxlength="250"></textarea>
    </div>

    
    
    <div class="text-end">
      <button type="submit" class="text-white bg-green-800 hover:bg-green-900 focus:outline-none focus:ring-green-800 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">{{$t('Submit')}}</button>
    </div>
    
  </form>

  <div v-if='loading' class="flex flex-col items-center mb-5 mt-8">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-1/6 mb-3 animate-spin-slow"><path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/></svg>
    <p>{{$t('Registration is sent.')}}</p>
  </div>
  
  <div v-if='!showForm  && !loading' class="flex flex-col items-center mb-5 mt-8">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fill-green-900 w-1/6 mb-3"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
    <p class="text-green-900">{{$t('Thank you! Your registration was sent successfully.')}}</p>
  </div>

</template>

<script>

import FormSelect from "@/components/Form/FormSelect.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormCheckbox from "@/components/Form/FormCheckbox.vue";
import axios from 'axios';

export default {
  name: 'RegistrationForm',
  components: {FormCheckbox, FormInput, FormSelect},
  data() {
    return {
      form : {
        name: '',
        house: '',
        adults: 0,
        children: 0,
        help_start: false,
        help_end: false,
        help_grill: false,
        help_bar: false,
        help_music: false,
        contact: false,
        food_type: false,
        food: false,
        tt_adults: false,
        tt_children: false,
        notes: ''
      },
      showForm: true,
      loading: false
    }
  },
  methods: {
    async submit() {

      this.loading = true;
      
      axios.post('/save.php', this.form)
          .then(response => {
            if(response.data.success) {
              this.showForm = false
            }
          })
          .catch(() => {
            
          }).finally(() => this.loading = false)
    }
  }
}
</script>
