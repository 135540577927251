<template>
  
  <label :for="id" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{ label }}</label>
  <input :id="id" @input="$emit('update:modelValue', $event.target.value)" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-green-800 focus:border-green-800 block w-full p-2.5" v-bind="$attrs">
  
</template>

<script>
export default {
  name: "FormInput",
  props: {
    id: {
      type: String
    },
    label: String
  }
}
</script>
