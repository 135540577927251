<template>

  <div class="flex items-center mb-4 ps-2">
    <input :id="id" type="checkbox" value="1" @input="$emit('update:modelValue', $event.target.checked)" class="w-4 h-4 text-green-800 bg-gray-100 border-gray-300 rounded focus:ring-green-800 checked:bg-green-800" v-bind="$attrs">
    <label :for="id" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: "FormCheckbox",
  props: {
    id: {
      type: String
    },
    label: String
  }
}
</script>
